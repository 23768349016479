<template>
  <el-dialog :title="isEdit?'编辑':'新增'" width="600px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="90px" :rules="rules">
      <el-form-item label="字典名称：" prop="dictionaryName">
        <el-input v-model="form.dictionaryName" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="字典类型：" prop="dictionaryType">
        <el-input v-model="form.dictionaryType" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio label="0">启用</el-radio>
          <el-radio label="1">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input class="inputWidth" type="textarea" :rows="2" placeholder="请输入" v-model="form.remark">
        </el-input>
      </el-form-item>

    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import Api from "@/api/devops/dictionary";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: () => {},
      type: Object,
    },
    isEdit: { type: Boolean, default: false },
  },
  watch: {
    show(val) {
      if (val) {
        this.isEdit = !_.isEmpty(this.detail);
        this.form = this.isEdit ? this.detail : {};
      } else {
        this.$refs["form"].resetFields();
      }
    },
  },
  data() {
    return {
      form: {},
      rules: {
        dictionaryName: [
          { required: true, message: "请输入字典名称", trigger: "blur" },
        ],
        dictionaryType: [
          { required: true, message: "请输入字典类型", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择字典类型状态", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.fetch();
        } else {
          return false;
        }
      });
    },

    async fetch() {
      let params = {
        ...this.form,
      };
      const fn = this.form.dictionaryId ? Api.updateType : Api.addType;
      let res = await fn(params);
      this.form.label = "";
      this.$msg.success("操作成功");
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
</style>