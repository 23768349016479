import http from '@/utils/axios'
const api = {
  //字典类型
  getTypeList: (data) => http.get(`/eds/system/dictionary/type/list`, data),
  addType: (data) => http.post(`/eds/system/dictionary/type/add`, data),
  updateType: (data) => http.put("eds/system/dictionary/type/update", data),
  deType: (data) => http.delete("eds/system/dictionary/type/delete/" + data),
  //字典数据
  getDataList: (data) => http.get(`/eds/system/dictionary/data/list`, data),
  addData: (data) => http.post(`/eds/system/dictionary/data/add`, data),
  updateData: (data) => http.put("eds/system/dictionary/data/update", data),
  deData: (data) => http.delete("eds/system/dictionary/data/delete/" + data),
  //插件管理
  getPlugInList: (data) => http.get(`/eds/plugin/manager/list`, data),
  addPlugIn: (data) => http.post(`/eds/plugin/manager/add`, data),
  updatePlugIn: (data) => http.put("eds/plugin/manager/update", data),
  dePlugIn: (data) => http.delete("eds/plugin/manager/delete/" + data),
}
  export default api

